// Screen title
.app_title_background {
	width: 100%;
	min-width: 32rem;
	padding: 1.5rem 0 1.5rem 0;

	.app_title {
		&_header {
			display: flex;
			margin: 0 auto;
			align-items: center;
			padding: 0 0 0 1.5rem;
			max-width: 140rem;
			transition: max-width .3s;	

			@include respond(big-pc) {
				width: calc(100% - 10rem);
				padding: 0 0 0 1.5rem;
			}	

			@include respond(small-tab) {
				width: calc(100% - 4rem);
				padding: 1.5rem 0 1rem 0;
				flex-direction: column;
			}

			@include respond(small-phone) {
				width: calc(100% - 2.8rem);
			}

			.mat-icon-button {
				color: $primary-text-color;
				height: 3.5rem;
				width: 3.5rem;
				line-height: 3.5rem;

				.mat-icon {
					font-size: 2rem;
					height: 2rem;
					width: 2rem;
					line-height: 1.7rem;
				}
			}
		}

		&_text {
			flex: 1 1 auto;
			display: flex;

			span {
				font-size: 1.6rem;
			}			

			label {
				font-size: 1.6rem;
				line-height: 1.4rem;
				color: $primary-text-color;
				text-align: center;
				align-self: center;
			}
		}

		&_buttons {
			display: flex;
			
			@include respond(small-tab) {
				margin-top: 2rem;
			}

			button {
				margin-left: 1rem;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}