// Interna table
.internal_table {
	border: 1px solid $gray-4;
	width: 100%;
	margin-bottom: 1rem;

	@include respond(small-tab) {
		margin-bottom: 1.5rem;
	}

	label {		
		font-weight: 600;
		font-size: 1.5rem;
		padding: .5rem 2rem;
		color: $primary-text-color;
		text-align: center;
		border-bottom: 2px solid $gray-4;
		width: 100%;
		display: block;
	}

	table {
		border-spacing: 0;
		width: 100%;

		thead {
			tr {
				th {
					border-bottom: 3px solid $gray-4;
					font-weight: 600;
					font-size: 1.5rem;
					padding: .5rem 1.5rem;
					color: $primary-text-color;
					text-align: center;
				}
			}
		}

		tbody {
			tr {
				td {
					color: $primary-text-color; 
					font-size: 1.5rem;
					text-align: center;
					padding: .5rem 1.5rem;
					border-top: 1px solid $gray-4;

					label {
						font-weight: normal;
						border: 0;
					}
				}
			}
		}
	}
}