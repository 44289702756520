// Table
.table {
	flex-direction: column;	
	display: inline-flex;
	min-width: 100%;

	&-content {
		width: 100%;
		overflow-x: auto;

		@include respond(small-tab) {
			overflow-x: initial;
		}
	}

	&_header {		
		display: flex;
		border-bottom: 2px solid $gray-4;
		border-right: 1px solid transparent;
		border-left: 1px solid transparent;
		padding-bottom: .5rem;

		@include respond(small-tab) {
			display: none;
		}

		&_element {			
			padding: 0;
		}

		label {
			color: $primary-text-color;
			font-weight: 600;
			font-size: 1.5rem;
		}
	}

	&_body {
		border: 1px solid $gray-4;
		border-width: 0 1px 1px 1px;
		display: flex;
		flex-direction: column;

		@include respond(small-tab) {
			border: 0;
		}

		& > div {
			display: flex;
			border-top: 1px solid $gray-4;

			&:last-child {
				border-bottom: 0;
			}

			@include respond(small-tab) {
				flex-direction: column;
				border: 1px solid $gray-4;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
				margin: 1rem 0;
				align-items: flex-start;
				padding: 1.8rem 2.2rem .5rem 2.2rem;
			}
		}

		&_element {			
			padding: 0.3rem 0;

			@include respond(small-tab) {
				display: flex;
			}

			label {
				color: $primary-text-color; 
				padding: 0.5rem 0;
				display: block;
				font-size: 1.5rem;
				color: $gray-8;
				position: relative;
				top: 4px;

				@include respond(small-tab) {
					top: 0;
				}

				&.title {
					display: none;
					font-weight: 600;
					color: $primary-text-color;
					font-size: 1.5rem;
					margin-right: 1rem;

					@include respond(small-tab) {
						display: block;
						width: 15rem;
						min-width: 15rem;
					}
				}

				@include respond(small-tab) {
					padding: 0;
				}
			}

			.mat-icon-button {
				color: $primary-text-color;
				height: 3.5rem;
				width: 3.5rem;
				line-height: 3.5rem;

				.mat-icon {
					font-size: 2rem;
					height: 2rem;
					width: 2rem;
					line-height: 1.7rem;
				}
			}
		}	
	}
}

// New Inspections Detail
.new-inspections-detail {
	&_values {
		app-values-b {
			width: 100%;

			.values-b_content {
				&.large {
					height: calc(100vh - 36.2rem);

					@include respond(medium-pc) {
						height: calc(100vh - 37rem);
					}

					@include respond(medium-tab) {
						height: calc(100vh - 38.8rem);
					}
				}

				.table_header_element,
				.table_body_element {
					&.values-b_actions {
						display: none;
					}
				}

				.table_body_element {
					label {
						top: 0;
					}
				}

				.table_body {
					.app_title_buttons,
					.internal_table {
						display: none !important;
					}
				}

				& > div {
					padding: 1.8rem 2.2rem;
				}
			}
		}
	}
}
