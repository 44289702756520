// Dialog
.cdk-overlay-pane {
  max-width: initial !important;
}

.mat-dialog-container {
  max-width: 80rem;
}

.dialog {
  .mat-dialog-actions {
    justify-content: flex-end;
    margin-bottom: 0;
    min-height: initial;
    padding: 1.5rem 0 0 0;
  }

  .mat-dialog-title {
    flex: 1 1 auto;
    margin-top: 0;
  }

  &_button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    border-bottom: 1px solid $gray-4;

    &-icon {
      cursor: pointer;
      transition: color .2s;
      height: 100%;
      margin-top: .2rem;

      &:hover {
        color: $corporative-color-1;
      }
    }
  }

  h1 {
    width: 100%;
    padding: 0 0 0 .5rem;
    font-size: 2.1rem;
    color: $primary-text-color;
    margin: 0 0 .8rem 0;
  }

  p {
    padding: 1rem .5rem 0 .5rem;
  }
}
