//Responsive
@mixin respond($breakpoint) {

	@if $breakpoint == min-phone { //320px
		@media only screen and (max-width: 21em) { 
			@content
		}
	}

	@if $breakpoint == small-phone { //400px
		@media only screen and (max-width: 25em) { 
			@content
		}
	}

	@if $breakpoint == medium-phone { //600px
		@media only screen and (max-width: 37.5em) { 
			@content
		}
	}

	@if $breakpoint == big-phone { //670px
		@media only screen and (max-width: 41.875em) { 
			@content
		}
	}

	@if $breakpoint == small-tab { //768px
		@media only screen and (max-width: 48em) {
			@content
		}
	}

	@if $breakpoint == standard-tab { //900px
		@media only screen and (max-width: 56.25em) {
			@content
		}
	}

	@if $breakpoint == medium-tab { //1024px
		@media only screen and (max-width: 64em) {
			@content
		}
	}

	@if $breakpoint == regular-tab { //1100px
		@media only screen and (max-width: 68.75em) {
			@content
		}
	}

	@if $breakpoint == big-tab { //1200px
		@media only screen and (max-width: 75em) { 
			@content
		}
	} 

	@if $breakpoint == small-pc { //1400px
		@media only screen and (max-width: 87em) { 
			@content
		}
	} 

	@if $breakpoint == medium-pc { //1600px
		@media only screen and (max-width: 100em) { 
			@content
		}
	} 

	@if $breakpoint == big-pc { //1800px
		@media only screen and (max-width: 113em) { 
			@content
		}
	} 
}