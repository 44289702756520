// Variables

// Fonts
$primary-font-stack: 'Arial', sans-serif;
$primary-text-color: #646464;

// Corporatives - Green
$corporative-color-1: #00893D;
$corporative-color-1-dark: #006C27;

// Background
$lighter-color: #FFFFFF;

// Gray
$gray-1: #F6F7F8;
$gray-2: #EBEEF0;
$gray-3: #DDE3E6;
$gray-4: #CED5DA;
$gray-5: #B0B0B0;
$gray-6: #AAB1B4;
$gray-8: #878D8F;
$bg-dark: #000000;

// Details
$red: #E62C27;
$yellow: #FCCF46;

// Menu
$menu-nav: 31rem;