@import '~@angular/material/theming';

@include mat-core(); 

$customPrimary: (
    50: #09ff77,
    100: #00ef6a,
    200: #00d55f,
    300: #00bc54,
    400: #00a248,
    500: #00893D,
    600: #006f32,
    700: #005626,
    800: #003c1b,
    900: #002310,
    A100: #23ff85,
    A200: #3cff93,
    A400: #56ffa1,
    A700: #000904,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$customAccent: (
    50: #171717,
    100: #242424,
    200: #313131,
    300: #3e3e3e,
    400: #4a4a4a,
    500: #575757,
    600: #717171,
    700: #7d7d7d,
    800: #8a8a8a,
    900: #979797,
    A100: #717171,
    A200: #646464,
    A400: #575757,
    A700: #a4a4a4,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$customWarn: (
    50: #f39c99,
    100: #f18582,
    200: #ee6f6c,
    300: #eb5955,
    400: #e9423e,
    500: #E62C27,
    600: #da1e19,
    700: #c31b17,
    800: #ad1814,
    900: #961511,
    A100: #f6b2b0,
    A200: #f9c8c7,
    A400: #fbdfde,
    A700: #7f120f,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$quality-portal-app-primary: mat-palette($customPrimary);
$quality-portal-app-accent: mat-palette($customAccent);

$quality-portal-app-warn: mat-palette($customWarn);

$quality-portal-app-theme: mat-light-theme((
  color: (
    primary: $quality-portal-app-primary,
    accent: $quality-portal-app-accent,
    warn: $quality-portal-app-warn,
  )
));

@include angular-material-theme($quality-portal-app-theme);

// Variables
@import "general-style/variables";
@import "general-style/responsive";
@import "general-style/inputs-buttons";
@import "general-style/screen-title";
@import "general-style/dialog";
@import "general-style/table";
@import "general-style/table-internal";
@import "general-style/datepicker";
@import "general-style/tooltip";


// General
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%;  
}

html,
body { 
  height: 100%;
}

body { 
  font-family: $primary-font-stack;
  margin: 0 auto;
  background: $gray-1;
  color: $primary-text-color;
  padding: 0 0 3.5rem 0;
  box-sizing: border-box;
  overflow-x:  hidden;
  min-width: 32rem;
  width: 100vw;

  * {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: $primary-font-stack;
  }
}