// Inputs
.mat-form-field {
	font-weight: 400;

	&-appearance-fill {
		.mat-form-field-flex {
			background-color: rgba(0,0,0,.0);
			border-radius: 0 0 0 0 !important;
			padding: 0 .3em 0 .3em !important;		
		}

		.mat-form-field-infix {
			border-top: 0;
			padding: 0.1em 0 0.5em 0;
			width: auto;
		}

		.mat-form-field-subscript-wrapper, {
			padding: 0 .6rem !important;
		}

		.mat-select-min-line {
			font-size: 1.5rem;
		}

		.mat-select-arrow-wrapper {
			transform: none !important;
		}
	}

	.mat-input-element,
	.mat-select-value {
		color: $primary-text-color;
	}
}

.mat-form-field:not(.mat-form-field-appearance-legacy) {
	.mat-form-field-prefix,
	.mat-form-field-suffix {
		.mat-icon-button {
			.mat-icon {
				height: 2rem;
				width: 2rem;
				line-height: 2rem;
				font-size: 2rem;
			}
		}
	}
}

input,
textarea,
select {
	&:-webkit-autofill,
	&:-webkit-autofill:hover, 
	&:-webkit-autofill:focus {
	  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
	  transition: background-color 5000s ease-in-out 0s;
	  -webkit-text-fill-color: $primary-text-color;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea.mat-input-element {
  padding: 0 !important;
  margin: 5px 0 !important;
}

// Validation
.mat-error{
	font-size: 1.2rem
}

// File
.file-input-element {
	display: flex;

	input[type="file"] {
		display: none;
	}

	.file-button {
		background: $corporative-color-1;
		transition: .2s;
		cursor: pointer;
		color: $lighter-color;
		border-radius: .4rem;
		text-transform: uppercase;
		padding: .7rem 2rem .5rem 2rem;
		margin-left: 2rem;
		position: relative;
		top: -3px;
		line-height: 2.2rem;
		height: 3.3rem;
		display: flex;
	}
}

// Buttons
.mat-flat-button {
	line-height: 2.8rem !important;
	padding: .3rem 2rem .1rem 2rem !important;

	&.mat-primary {
		transition: background-color .2s;

		&:hover {
			background-color: $corporative-color-1-dark;
		}
	}

	&.mat-accent {
		transition: background-color .2s;

		&:hover {
			background-color: $gray-8;
		}
	}
}

// Links
a {
	color: $corporative-color-1;
	text-decoration: none;

	&:link,
	&:active,
	&:focus,
	&:visited {
		color: $corporative-color-1;
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
	}
}